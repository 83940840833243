import React from 'react';
import styled from 'styled-components';
import BloqueUbicacion from './BloqueUbicacion';

const ContactContainer = styled.div`
    max-width: 800px;
    margin: 120px auto 40px; /* учитываем фиксированный Header */
    padding: 40px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #0d47a1;
`;

const InfoBlock = styled.div`
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;
    color: #333;
    line-height: 1.5;
`;

const InfoItem = styled.p`
    margin: 5px 0;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 40px;
`;

const Input = styled.input`
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const Textarea = styled.textarea`
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    min-height: 150px;
`;

const Button = styled.button`
    padding: 12px;
    background-color: #3b82f6;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #2563eb;
    }
`;

const Contact = () => {
  return (
    <ContactContainer>
      <Title>Contacto</Title>
      <InfoBlock>
        <InfoItem><strong>Teléfono:</strong> +34 987 654 321</InfoItem>
        <InfoItem><strong>Email:</strong> contacto@infinitytech.es</InfoItem>
        <InfoItem><strong>Dirección:</strong> Calle Falsa, 123, Madrid, España</InfoItem>
      </InfoBlock>
      <Form action="thanks/index.php" method="post">
        <Input type="text" name="nombre" placeholder="Nombre" required />
        <Input type="email" name="correo" placeholder="Correo electrónico" required />
        <Input type="text" name="asunto" placeholder="Asunto" required />
        <Textarea name="mensaje" placeholder="Escribe tu mensaje aquí..." required />
        <Button type="submit">Enviar</Button>
      </Form>
      <BloqueUbicacion />
    </ContactContainer>
  );
};

export default Contact;
