import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #0d47a1, #1976d2);
    box-sizing: border-box;
`;

const ContentBlock = styled.div`
    max-width: 800px;
    margin: 40px auto;
    padding: 40px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #333;

  p {
    margin-bottom: 16px;
  }

  a {
    color: #3b82f6;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const CookiePolicy = () => {
  return (
    <Wrapper>
      <ContentBlock>
        <Title>Política de Cookies</Title>
        <Content>
          <p>
            En nuestro sitio web, utilizamos cookies para mejorar su experiencia y optimizar el rendimiento de nuestra plataforma. Las cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita nuestro sitio.
          </p>
          <p>
            Utilizamos cookies propias y de terceros para analizar el uso del sitio, personalizar el contenido y ofrecerle publicidad de acuerdo a sus intereses. Al continuar navegando en nuestro sitio, usted acepta el uso de cookies según lo descrito en esta política.
          </p>
          <p>
            Puede configurar su navegador para rechazar o eliminar cookies; sin embargo, esto podría afectar la funcionalidad del sitio y limitar su experiencia.
          </p>
          <p>
            Para obtener más información, consulte nuestra <a href="/terms" target="_blank" rel="noopener noreferrer">Política de Privacidad</a>.
          </p>
        </Content>
      </ContentBlock>
    </Wrapper>
  );
};

export default CookiePolicy;
