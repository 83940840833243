import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
    background: linear-gradient(90deg, #0d47a1, #1976d2);
    color: #fff;
    padding: 40px 20px;
`;

const FooterContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const InfoSection = styled.div`
    flex: 1;
    min-width: 250px;
    margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
    font-size: 20px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    font-size: 16px;
    margin: 5px 0;
`;

const LinkSection = styled.div`
    flex: 1;
    min-width: 250px;
    margin-bottom: 20px;
`;

const SocialSection = styled.div`
    flex: 1;
    min-width: 250px;
    margin-bottom: 20px;
`;

const FooterLink = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: block;
    margin: 5px 0;

    &:hover {
        text-decoration: underline;
    }
`;

const FooterBottom = styled.div`
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding-top: 20px;
    font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <InfoSection>
          <SectionTitle>Infinity Tech España</SectionTitle>
          <Text>Dirección: Calle Gran Vía, 21, 280111 Madrid, España</Text>
          <Text>Teléfono: +34 912 345 222</Text>
          <Text>Email: info@infinit2ytech.es</Text>
        </InfoSection>
        <LinkSection>
          <SectionTitle>Enlaces</SectionTitle>
          <FooterLink href="/" target="_blank" rel="noopener noreferrer">
            Inicio
          </FooterLink>
          <FooterLink href="/about" target="_blank" rel="noopener noreferrer">
            Sobre nosotros
          </FooterLink>
          <FooterLink href="/contact" target="_blank" rel="noopener noreferrer">
            Contacto
          </FooterLink>
        </LinkSection>
        <SocialSection>
          <SectionTitle>Síguenos</SectionTitle>
          <FooterLink href="https://www.facebook.com/InfinityTechEspana" target="_blank" rel="noopener noreferrer">
            Facebook
          </FooterLink>
          <FooterLink href="https://twitter.com/InfinityTechEs" target="_blank" rel="noopener noreferrer">
            Twitter
          </FooterLink>
          <FooterLink href="https://www.linkedin.com/company/infinity-tech-espana" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </FooterLink>
          <FooterLink href="https://www.instagram.com/infinitytechespana" target="_blank" rel="noopener noreferrer">
            Instagram
          </FooterLink>
        </SocialSection>
      </FooterContent>
      <FooterBottom>
        &copy; {new Date().getFullYear()} Infinity Tech España. Todos los derechos reservados.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
