import React from "react";
import styled from "styled-components";

// Контейнер для всего блока с ярким жёлтым фоном
const ContenedorProyecto = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px 20px;
    justify-content: center;
    background-color: #ffeb3b;
`;

// Контейнер для изображения
const ContenedorImagen = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Изображение проекта
const Imagen = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

// Контейнер для контента (текстовый блок)
const ContenedorContenido = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

// Заголовок проекта
const TituloProyecto = styled.h2`
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
`;

// Описание проекта
const DescripcionProyecto = styled.p`
    font-size: 16px;
    color: #666;
    line-height: 1.5;
`;

const BloqueProyectoInfinity = () => {
  return (
    <ContenedorProyecto>
      <ContenedorImagen>
        <Imagen src="/infinity.jpg" alt="Proyecto Infinity" />
      </ContenedorImagen>
      <ContenedorContenido>
        <TituloProyecto>Proyecto Infinity: Innovación sin Límites</TituloProyecto>
        <DescripcionProyecto>
          Descubre Infinity, donde la creatividad y la tecnología se unen para impulsar soluciones que trascienden fronteras. Nuestro proyecto busca redefinir el futuro, ofreciendo herramientas innovadoras y estrategias disruptivas para transformar industrias. Únete a la revolución Infinity y sé parte del cambio.
        </DescripcionProyecto>
      </ContenedorContenido>
    </ContenedorProyecto>
  );
};

export default BloqueProyectoInfinity;
