import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";

const ContentWrapper = styled.div`
    width: 100%;
    padding: 60px 20px;
    background-color: #fff;
    box-sizing: border-box;
`;

const ContentBlock = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 32px;
  color: #0d47a1;
  text-align: center;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const About = () => {
  return (
    <>
      <Header />
      <ContentWrapper>
        <ContentBlock>
          <Title>Acerca de Infinity Tech España</Title>
          <Paragraph>
            Infinity Tech España es una empresa innovadora dedicada a la transformación digital y al desarrollo de soluciones en inteligencia artificial. Fundada en Madrid, nos especializamos en automatizar procesos empresariales y ofrecer herramientas tecnológicas que impulsan el crecimiento y la eficiencia.
          </Paragraph>
          <Paragraph>
            Nuestro equipo de expertos trabaja incansablemente para diseñar e implementar proyectos que no solo resuelven problemas actuales, sino que también anticipan las necesidades del futuro. ¡Bienvenido a un mundo de posibilidades infinitas!
          </Paragraph>
        </ContentBlock>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default About;
