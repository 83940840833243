import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

// Нові стилі
const HeaderWrapper = styled.header`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: ${({ isScrolled }) =>
            isScrolled ? "linear-gradient(90deg, #0d47a1, #1976d2)" : "#1976d2"};
    padding: ${({ isScrolled }) => (isScrolled ? "8px 0" : "20px 0")};
    transition: all 0.3s ease;
    box-shadow: ${({ isScrolled }) =>
            isScrolled ? "0 2px 6px rgba(0, 0, 0, 0.2)" : "none"};
`;

const HeaderContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

// Ліва секція меню
const NavLeft = styled.ul`
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;

// Права секція меню
const NavRight = styled.ul`
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;

const NavItem = styled.li``;

const NavLink = styled(Link)`
    text-decoration: none;
    color: ${({ $active }) => ($active ? "#ffeb3b" : "#ffffff")};
    font-size: 18px;
    transition: color 0.3s;
    font-weight: ${({ $active }) => ($active ? "bold" : "normal")};

    &:hover {
        color: #ffeb3b;
        font-weight: bold;
    }
`;

// Центр логотипу
const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoIcon = styled.div`
    width: 60px;
    height: 60px;
    background: radial-gradient(circle, #ff5722, #e64a19);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    & span {
        color: #fff;
        font-size: 26px;
        font-weight: bold;
    }
`;

const LogoText = styled.span`
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 200;
    @media (max-width: 768px) {
        display: flex;
    }
`;

const Bar = styled.span`
  height: 3px;
  width: 25px;
  background-color: #ffffff;
  margin: 4px 0;
  transition: 0.4s;
`;

const MobileMenu = styled.ul`
  list-style: none;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  background-color: #1976d2;
  position: absolute;
  top: 100%;
  right: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: ${({ open }) => (open ? "flex" : "none")};
  @media (min-width: 769px) {
    display: none;
  }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContainer>
        {/* Ліва частина меню */}
        <NavLeft>
          <NavItem>
            <NavLink to="/" $active={location.pathname === "/"} onClick={handleLinkClick}>
              Inicio
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/about" $active={location.pathname === "/about"} onClick={handleLinkClick}>
              Sobre nosotros
            </NavLink>
          </NavItem>
        </NavLeft>

        {/* Центр логотипу */}
        <LogoWrapper>
          <LogoLink to="/">
            <LogoIcon>
              <span>I</span>
            </LogoIcon>
            <LogoText>InfinityQ</LogoText>
          </LogoLink>
        </LogoWrapper>

        {/* Права частина меню */}
        <NavRight>
          <NavItem>
            <NavLink to="/contact" $active={location.pathname === "/contact"} onClick={handleLinkClick}>
              Contacto
            </NavLink>
          </NavItem>
        </NavRight>

        {/* Гамбургер для мобільної версії */}
        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>

        <MobileMenu open={isMenuOpen}>
          <li>
            <NavLink to="/" $active={location.pathname === "/"} onClick={handleLinkClick}>
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" $active={location.pathname === "/about"} onClick={handleLinkClick}>
              Sobre nosotros
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" $active={location.pathname === "/contact"} onClick={handleLinkClick}>
              Contacto
            </NavLink>
          </li>
        </MobileMenu>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
