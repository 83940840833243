import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { FaBullseye, FaBuilding, FaSmile } from "react-icons/fa";

const Container = styled.section`
  background: linear-gradient(90deg, #ffeb3b, #ffeb3b);
  padding: 40px 20px;
  color: #0d47a1;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Card = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 250px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const IconWrapper = styled.div`
  font-size: 40px;
  margin-bottom: 10px;
`;

const Number = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-size: 16px;
`;

const WhyChooseUs = () => {
  return (
    <Container>
      <Title>Por qué elegirnos</Title>
      <ContentContainer>
        <Card>
          <IconWrapper>
            <FaBullseye />
          </IconWrapper>
          <Number>
            <CountUp end={15583} duration={3} separator="," />
          </Number>
          <Label>Promesas cumplidas</Label>
        </Card>
        <Card>
          <IconWrapper>
            <FaBuilding />
          </IconWrapper>
          <Number>
            <CountUp end={300} duration={3} separator="," />
          </Number>
          <Label>Proyectos completados</Label>
        </Card>
        <Card>
          <IconWrapper>
            <FaSmile />
          </IconWrapper>
          <Number>
            <CountUp end={1400} duration={3} separator="," />
          </Number>
          <Label>Clientes satisfechos</Label>
        </Card>
      </ContentContainer>
    </Container>
  );
};

export default WhyChooseUs;
