import React from "react";
import styled from "styled-components";

// Контейнер для блока местоположения (100% ширины)
const UbicacionContainer = styled.div`
    width: 100%;
    padding: 40px 20px;
    background-color: #1976d2;
    box-sizing: border-box;
`;

// Внутренний контейнер для центрирования контента
const ContenidoInterno = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

// Заголовок блока
const Titulo = styled.h2`
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
`;

// Описание о команде и маршруте
const Descripcion = styled.p`
    font-size: 16px;
    color: #e0e0e0;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;
`;

// Контейнер для карты
const MapaContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const BloqueUbicacion = () => {
  return (
    <UbicacionContainer>
      <ContenidoInterno>
        <Titulo>Estamos ubicados en Madrid, España</Titulo>
        <Descripcion>
          Nuestro equipo se reúne en el corazón de Madrid, en una ubicación estratégica y de fácil acceso.
          Puedes llegar en metro o autobús, y contamos con amplias conexiones desde la estación central.
          Ven a conocernos y descubre cómo trabajamos juntos para impulsar la innovación.
        </Descripcion>
        <MapaContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.235748535976!2d-3.703790384608256!3d40.41677597936468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42289e32381a05%3A0x8bff88b2b2b6a0e!2sMadrid%2C%20Spain!5e0!3m2!1sen!2s!4v1615588055604!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Mapa de Madrid"
          ></iframe>
        </MapaContainer>
      </ContenidoInterno>
    </UbicacionContainer>
  );
};

export default BloqueUbicacion;
