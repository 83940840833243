import React, { useState } from "react";
import styled from "styled-components";

// Контейнер для FAQ с жёлтым фоном, занимающий 100% ширины экрана
const FAQContainer = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #ffeb3b;
    box-sizing: border-box;
`;

// Внутренний контейнер для ограничения максимальной ширины и центрирования контента
const ContenidoInterno = styled.div`
    max-width: 800px;
    margin: 40px auto;
`;

// Отдельный элемент FAQ с обновлённой границей
const FAQItem = styled.div`
    border-bottom: 1px solid #1976d2;
`;

// Заголовок вопроса с более тёмным жёлтым фоном и контрастным текстом
const Question = styled.h3`
    margin: 0;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1976d2;
    color: #333;
`;

// Текст ответа с тёмным текстом для лучшей читаемости
const Answer = styled.div`
  padding: 15px;
  font-size: 16px;
  color: #333;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const BloqueFAQ = () => {
  const faqItems = [
    {
      question: "¿Qué es Infinity y cómo funciona?",
      answer:
        "Infinity es una plataforma innovadora que combina inteligencia artificial con soluciones empresariales para optimizar procesos y fomentar la innovación."
    },
    {
      question: "¿Cuáles son las principales ventajas de unirse a Infinity?",
      answer:
        "Al formar parte de Infinity, accedes a tecnología de vanguardia, asesoramiento personalizado y una comunidad dinámica que impulsa el crecimiento profesional."
    },
    {
      question: "¿Cómo puedo registrarme y empezar a participar?",
      answer:
        "El proceso de registro es sencillo. Solo debes completar el formulario en nuestro sitio web y seguir las instrucciones para activar tu cuenta."
    },
    {
      question: "¿Qué tipo de proyectos desarrolla Infinity?",
      answer:
        "Infinity impulsa proyectos en áreas como inteligencia artificial, automatización y desarrollo tecnológico, enfocados en transformar la manera en que operan las empresas."
    },
    {
      question: "¿Es necesaria experiencia previa para unirse a Infinity?",
      answer:
        "No, Infinity está diseñado tanto para principiantes como para profesionales, ofreciendo recursos y apoyo para adaptarse a diferentes niveles de experiencia."
    },
    {
      question: "¿Dónde puedo obtener más información sobre Infinity?",
      answer:
        "Puedes encontrar más detalles en nuestro sitio web o contactando a nuestro equipo de soporte, quienes estarán encantados de ayudarte."
    }
  ];

  const [openItems, setOpenItems] = useState(
    Array(faqItems.length).fill(false)
  );

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <FAQContainer>
      <ContenidoInterno>
        {faqItems.map((item, index) => (
          <FAQItem key={index}>
            <Question onClick={() => toggleItem(index)}>
              {item.question} <span>{openItems[index] ? "-" : "+"}</span>
            </Question>
            <Answer isOpen={openItems[index]}>{item.answer}</Answer>
          </FAQItem>
        ))}
      </ContenidoInterno>
    </FAQContainer>
  );
};

export default BloqueFAQ;
