import React from "react";
import styled from "styled-components";

// Основний контейнер із стилями, що нагадують хедер
const HeaderSection = styled.header`
    background: linear-gradient(90deg, #0d47a1, #1976d2);
    padding: 40px 20px;
    color: #fff;
`;

// Контейнер для блоків із максимальними ширинами та центруванням
const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: 50px auto 0 auto;
`;

// Блок з інформацією (відео + текст)
const InfoBlock = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
`;

// Відео-обгортка з співвідношенням сторін 16:9
const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

// Описовий текст із світлими кольорами
const Text = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
`;

// Блок з формою заявки, стилізований під хедер
const FormBlock = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
`;

// Стилізація форми
const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

// Стилізація інпутів
const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

// Кнопка у стилі яскравого акценту
const Button = styled.button`
    padding: 12px;
    background-color: #ffeb3b;
    color: #0d47a1;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #fdd835;
    }
`;

// Контейнер для угоди з умовами із світлим текстом
const AgreementContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;

  input[type="checkbox"] {
    margin-right: 8px;
  }

  a {
    color: #ffeb3b;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const InfinityComponent = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Відправка форми на сервер
    fetch("thanks/index.php", {
      method: "POST",
      body: formData,
    }).catch((error) => {
      console.error("Error submitting form:", error);
    });

    alert("Solicitud enviada");
    e.target.reset();
  };

  return (
    <HeaderSection>
      <ContentContainer>
        <InfoBlock>
          <VideoWrapper>
            <StyledIframe
              src="https://www.youtube.com/embed/-0zKF-ZERIE?si=G3-bPbFUp_5YWM6E"
              title="Infinity Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoWrapper>
          <Text>
            El infinito es un concepto que desafía la comprensión humana y despierta la imaginación. Representa la posibilidad de lo ilimitado, un viaje sin fin a través de ideas, sueños y descubrimientos. En nuestro proyecto, Infinity simboliza la unión de creatividad, tecnología y pasión, invitando a explorar nuevos horizontes y a romper barreras en la búsqueda de un futuro lleno de oportunidades.
          </Text>
        </InfoBlock>
        <FormBlock>
          <h3>Registro</h3>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Ingrese su nombre"
              required
            />
            <Input
              type="text"
              id="apellido"
              name="apellido"
              placeholder="Ingrese su apellido"
              required
            />
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="ejemplo@correo.com"
              required
            />
            <Input
              type="tel"
              id="telefono"
              name="telefono"
              placeholder="+34 600 000 000"
              required
            />
            <AgreementContainer>
              <input type="checkbox" id="terms" name="terms" required />
              <label htmlFor="terms">
                Acepto los{" "}
                <a href="/terms" rel="noopener noreferrer">
                  Términos y Condiciones
                </a>{" "}
                y la{" "}
                <a href="/cookies" rel="noopener noreferrer">
                  Política de Cookies
                </a>.
              </label>
            </AgreementContainer>
            <Button type="submit">Enviar solicitud</Button>
          </Form>
        </FormBlock>
      </ContentContainer>
    </HeaderSection>
  );
};

export default InfinityComponent;
