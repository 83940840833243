import React from "react";
import styled from "styled-components";

// Основной контейнер с градиентным фоном, аналогичным хедеру
const Container = styled.section`
    background: linear-gradient(90deg, #0d47a1, #1976d2);
    padding: 60px 20px;
    color: #fff;
`;

// Контейнер для карточек с центральным выравниванием
const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
`;

// Индивидуальная карточка с полупрозрачным фоном и плавной анимацией при наведении
const Card = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 320px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    }
`;

// Изображение карточки
const CardImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

// Контейнер для текстового содержимого карточки
const CardContent = styled.div`
    padding: 20px;
    text-align: center;
`;

// Заголовок карточки с акцентным цветом
const CardTitle = styled.h3`
    font-size: 22px;
    margin-bottom: 10px;
    color: #ffeb3b;
`;

// Описание карточки
const CardDescription = styled.p`
  font-size: 16px;
  line-height: 1.4;
  color: #f1f1f1;
`;

const PrivilegiosInfinity = () => {
  return (
    <Container>
      <CardsContainer>
        <Card>
          <CardImage src="/reacti3.jpg" alt="Innovación Continua" />
          <CardContent>
            <CardTitle>Innovación Continua</CardTitle>
            <CardDescription>
              Con Infinity, accedes a un flujo constante de ideas innovadoras que transforman la manera en que haces negocios.
            </CardDescription>
          </CardContent>
        </Card>
        <Card>
          <CardImage src="/reacti2.jpg" alt="Soluciones Integrales" />
          <CardContent>
            <CardTitle>Soluciones Integrales</CardTitle>
            <CardDescription>
              Ofrecemos un paquete completo de servicios tecnológicos para potenciar cada área de tu empresa y maximizar resultados.
            </CardDescription>
          </CardContent>
        </Card>
        <Card>
          <CardImage src="/reacti1.jpg" alt="Expertos en Tecnología" />
          <CardContent>
            <CardTitle>Expertos en Tecnología</CardTitle>
            <CardDescription>
              Nuestro equipo de especialistas implementa estrategias personalizadas que impulsan la transformación digital.
            </CardDescription>
          </CardContent>
        </Card>
      </CardsContainer>
    </Container>
  );
};

export default PrivilegiosInfinity;
