import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
    max-width: 800px;
    margin: 120px auto 40px; /* учитывается высота Header */
    padding: 40px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
`;

const Content = styled.div`
    font-size: 16px;
    line-height: 1.6;
    color: #333;

    p {
        margin-bottom: 16px;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <Container>
        <Title>Términos y Condiciones</Title>
        <Content>
          <p>
            Bienvenido a Infinity Tech España. Al acceder a este sitio, aceptas cumplir con los presentes términos y condiciones, los cuales podrán ser modificados sin previo aviso. Te recomendamos revisarlos periódicamente.
          </p>
          <p>
            <strong>1. Uso del Sitio Web:</strong> El usuario se compromete a utilizar el sitio de forma responsable y de acuerdo con la legislación vigente. Queda estrictamente prohibido emplear el sitio para actividades que comprometan la seguridad, privacidad o integridad de los datos.
          </p>
          <p>
            <strong>2. Propiedad Intelectual:</strong> Todo el contenido presente en este sitio, incluyendo textos, imágenes, logotipos y software, es propiedad exclusiva de Infinity Tech España y se encuentra protegido por las leyes de propiedad intelectual.
          </p>
          <p>
            <strong>3. Limitación de Responsabilidad:</strong> Infinity Tech España no se hace responsable de daños directos, indirectos o consecuentes derivados del uso o la imposibilidad de uso del sitio web.
          </p>
          <p>
            <strong>4. Modificaciones:</strong> Nos reservamos el derecho de modificar, actualizar o eliminar cualquiera de los términos aquí expuestos en cualquier momento, sin notificación previa. El uso continuado del sitio implicará la aceptación de dichos cambios.
          </p>
          <p>
            <strong>5. Legislación Aplicable:</strong> Estos términos se regirán por la legislación española. En caso de controversia, las partes se someterán a la jurisdicción exclusiva de los tribunales de Madrid.
          </p>
          <p>
            Si tienes alguna pregunta o necesitas más información sobre estos Términos y Condiciones, no dudes en contactarnos.
          </p>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
